<!-- cp 상세보기 -->
<template>
  <v-container
    id="partnersView"
    fluid
    tag="section">
    <v-row
            align="center"
            justify="center">
      <v-col cols="12">
      <base-material-card
              color="green">
        <template v-slot:heading>
          <h1 class="display-2 font-weight-regular">
            {{ partnersName }} 정보
          </h1>
        </template>
        <v-card-text>
          <v-row class="text-no-wrap mb-3 mt-5"
                  align="center">
            <v-col cols="1" class="text-right grey--text">
              CP명
            </v-col>
            <v-col cols="4">
              {{vModel.data.name}}
            </v-col>
            <v-col cols="1" class="text-right grey--text">
              정산율
            </v-col>
            <v-col cols="4">
              {{vModel.data.commission}} %
            </v-col>
          </v-row>
          <v-row class="text-no-wrap mb-3"
                  align="center">
            <v-col cols="1" class="text-right grey--text">
              계정 이메일
            </v-col>
            <v-col cols="4">
              {{vModel.data.user.email}}
            </v-col>
            <v-col cols="1" class="text-right grey--text">
              이름
            </v-col>
            <v-col cols="4">
              {{vModel.data.user.name}}
            </v-col>
          </v-row>
          <v-row class="text-no-wrap mb-3"
                  align="center">
            <v-col cols="1" class="text-right grey--text">
              대표자명
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.ceo}}
            </v-col>
            <v-col cols="1" class="text-right grey--text">
              주소
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.address}}
            </v-col>
          </v-row>
          <v-row class="text-no-wrap mb-3"
                  align="center">
            <v-col cols="1" class="text-right grey--text">
              담당자명
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.managerName}}
            </v-col>
            <v-col cols="1" class="text-right grey--text">
              전화번호
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.managerPhone}}
            </v-col>
          </v-row>
          <v-row class="text-no-wrap mb-3"
                  align="center">
            <v-col cols="1" class="text-right grey--text">
              담당자 이메일
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.managerEmail}}
            </v-col>
            <v-col cols="1" class="text-right grey--text">
              사업자등록번호
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.businessNo}}
            </v-col>
          </v-row>
          <v-row class="text-no-wrap mb-3"
                  align="center">
            <v-col cols="1" class="text-right grey--text">
              은행명
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.bankName}}
            </v-col>
            <v-col cols="1" class="text-right grey--text">
              계좌번호
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.bankAccount}}
            </v-col>
          </v-row>
          <v-row class="text-no-wrap mb-3"
                  align="center">
            <v-col cols="1" class="text-right grey--text">
              세금계산서<br>발행 이메일
            </v-col>
            <v-col cols="4">
              {{vModel.data.company.taxEmail}}
            </v-col>
            <v-col cols="1" class="text-right grey--text">
              사이트 주소
            </v-col>
            <v-col cols="4">
              {{vModel.data.site_url}}
            </v-col>
          </v-row>

          <v-row class="text-no-wrap mb-3"
                 align="center">
            <v-col cols="1" class="text-right grey--text">
              제휴 상태
            </v-col>
            <v-col cols="4">
              <v-select :items="config.contractStatusOptions"
                        v-model="vModel.data.contract_status_id"
                        disabled
                        color="secondary" />
            </v-col>
          </v-row>

          <v-row>
            <v-col cols="1" class="text-right grey--text">
              매체 구독 현황
            </v-col>
            <v-col cols="4" v-if="response.mediaList">
              <p v-if="response.partners">
                {{response.partners.auto === 1 ? '구독 자동 승인' : '구독 자동 미승인'}}
              </p>
              <div style="max-height:350px;overflow-y: auto;border: solid 1px #ddd;border-width: 1px 0;">
                <v-data-table
                        style="width:100%"
                        :headers="config.headers"
                        :items="response.mediaList"
                        :items-per-page="Number(response.mediaList.length)"
                        hide-default-footer>
                  <template v-slot:item="{ item }">
                    <tr>
                      <td class="text-center">{{ item.kor_name }}</td>
                      <td class="text-center" v-if="response.partners.linkPartner.length > 0">
                        <template v-if="resultLinkPartners(item)">
                          -
                        </template>
                        <template v-else>
                          <template v-if="resultLinkPartners2(item) === 0">
                            <v-btn @click="putLinkPartners(item)" small rounded color="success">구독 승인</v-btn>
                          </template>
                          <span v-else>구독중</span>
                        </template>
                      </td>
                      <td v-else class="text-center">-</td>
                    </tr>
                  </template>
                </v-data-table>
              </div>
            </v-col>
          </v-row>
          <v-col cols="10">
            <v-row justify="center" align="center" style="margin-top:30px;">
              <v-btn
                      color="default"
                      default
                      @click="beforePage">
                취소
              </v-btn>
              <v-btn
                      color="success"
                      default
                      @click="partnersEdit">
                수정
              </v-btn>
            </v-row>
          </v-col>
          <v-divider class="mt-5 mb-5" />
          <v-row justify="space-between" align="center" class="mb-3">
            <h2 style="padding: 20px 10px;">
              {{ partnersName }} 컨텐츠
            </h2>
            <v-btn @click="goPartners" color="default">컨텐츠 리스트 바로가기</v-btn>
          </v-row>
          <v-row justify="space-between">
            <template v-for="val in config.contentsCard">
              <v-card outlined style="width:16%;margin-top:0;" :key="val.title">
                <v-card-title class="justify-center font-weight-regular pa-2" style="color:#fff;background:#4CAF50">
                  {{val.title}}
                </v-card-title>
                <v-card-title class="justify-center font-weight-bold pa-3">
                  {{numReplace(val.value)}}
                </v-card-title>
              </v-card>
            </template>
          </v-row>
        </v-card-text>
      </base-material-card>
      </v-col>
    </v-row>
    <base-material-snackbar
      v-model="vModel.snackbar"
      :type="vModel.snackbarType"
      v-bind="{
        'top': true,
        'right': true
      }">
      <span style="display:block;width:300px;margin:0;">{{vModel.errorMessage}}</span>
    </base-material-snackbar>
  </v-container>
</template>

<script>
import { mapActions } from 'vuex'
import { common } from '../../mixins/common.js'

export default {
  mixins: [ common ],
  created () {
    this.refresh()
  },
  data () {
    return {
      response: {
        routeName: this.$route.name,
        partners: null,
        media: null,
        mediaList: null,
        edit: null,
      },
      keyword: null,
      contentSelectedData: [],
      vModel: {
        page: 1,
        valid: true,
        partner: '',
        data: {
          mode: 'default',
          name: '',
          user: {
            name: '',
            email: '',
            password: '',
            passwordConfirmation: ''
          },
          company: {
            ceo: '',
            address: '',
            businessNo: '',
            taxEmail: '',
            managerName: '',
            managerPhone: '',
            managerEmail: '',
            bankName: '',
            bankAccount: '',
          }
        },
        submitStatus: null,
        snackbar: false,
        snackbarType: 'warning',
        errorMessage: null,
        emailChk: false,
        userEdit: false,
        filter: 'all',
        contentSelected: [],
        contentSelectedIsOn: false,
        contentSelectedBtn: [ true, true ],
      },
      datePicker: {
        start: {
          date: '',
          menu: false
        },
        end: {
          date: '',
          menu: false
        }
      },
      config: {
        linkPartners: null,
        headers: [
          { text: '매체명', value: 'name', align: 'center' },
          { text: '상태', value: 'status', align: 'center' },
        ],
        contentsCard: [],
        contractStatusOptions: [
          // { value: 'all', text: '전체' },
          { value: 1, text: '등록' },
          { value: 2, text: '준비 중' },
          { value: 3, text: '제휴 중' },
          { value: 4, text: '일시 정지' },
          { value: 6, text: '보류' },
          { value: 5, text: '중단' },
          { value: 7, text: '보관' }
        ]
      },
    }
  },
  methods: {
    ...mapActions(['xhttp']),
    snack (type, val) {
      this.vModel.errorMessage = val
      this.vModel.snackbarType = type
      this.vModel.snackbar = true
    },
    /* 페이지 로드시 */
    refresh () {
      this.getPartners()
    },
    /* 수정 버튼 클릭시 */
    partnersEdit () {
      this.$router.push({ name: 'partnersEdit', params: { partners_id: this.$route.params.partners_id } })
    },
    /* 컨텐츠 리스트 클릭 */
    goPartners () {
      this.$router.push({ name: 'contentsList', query: { partner: this.$route.params.partners_id } })
    },
    /* 구독 신청 확인 */
    resultLinkPartners (val) {
      let list = this.config.linkPartners.filter(function ($value) {
        return $value.id === val.id
      })
      if (list.length > 0) {
        // 신청한거
        return false
      } else {
        // 신청안한거
        return true
      }
    },
    /* 구독 신청 중일때 해당값 return */
    resultLinkPartners2 (val) {
      let list = this.config.linkPartners.filter(function ($value) {
        return $value.id === val.id
      })
      return list[0].value
    },
    /* 구독 승인하기 */
    putLinkPartners (val) {
      if (confirm('구독 승인하겠습니까?')) {
        this.xhttp({
          url: '/' + val.key + '/link/' + this.$route.params.partners_id,
          method: 'put',
          data: null
        }).then((response) => {
          if (response.status === 200) {
            this.response.putLink = response.data.data
            this.snack('success', '승인 완료되었습니다.')
            this.getPartners()
          }
        }).catch((error) => {
          if (error.message === 'Request failed with status code 401') {
            this.$router.push({ 'name': 'UserLogin' })
          } else {
            this.snack('warning', '관리자에게 문의 바랍니다.')
          }
        })
      }
    },
    /* 매체 리스트 가져오기 */
    mediaList () {
      let send = {
        orderby: 'created',
        direction: 'desc',
        per: 250,
        contract_status_id: 3,
        resolution: 'lo',
      }
      this.xhttp({
        url: '/media',
        method: 'get',
        params: send
      }).then((response) => {
        if (response.status === 200) {
          this.response.media = response.data.data.media
          for (let i = 0; i < this.response.media.length; i++) {
            this.response.media[i].idx = i
          }
          let list = this.config.linkPartners
          let arr = []
          if (this.config.linkPartners) {
            /* 구독된 cp 먼저 arr에 넣기 */
            for (let i = 0; i < list.length; i++) {
              let result = this.response.media.filter(function ($value) {
                return $value.id === list[i].id
              })
              if (result.length > 0) {
                arr.push(result[0])
              }
            }
            /* 구독되지 않은 cp 를 arr 에 넣기 */
            let mediaList = [ ...this.response.media ]
            for (let i = 0; i < arr.length; i++) {
              mediaList[arr[i].idx] = null
            }
            mediaList = mediaList.filter(function ($value) {
              return $value
            })
            arr = arr.concat(mediaList)
          } else {
            arr = list
          }
          this.response.mediaList = arr
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
    /* cp 상세정보 가져오기 */
    getPartners () {
      let id = this.$route.params.partners_id
      this.xhttp({
        url: '/partners/' + id,
        method: 'get',
        params: null
      }).then((response) => {
        if (response.status === 200) {
          this.response.partners = response.data.data.partner
          this.vModel.data = this.response.partners
          this.vModel.data.user.password = ''
          this.vModel.data.user.passwordConfirmation = ''
          if (!this.vModel.data.company) {
            this.vModel.data.company = {
              ceo: '',
              address: '',
              businessNo: '',
              taxEmail: '',
              managerName: '',
              managerPhone: '',
              managerEmail: '',
              bankName: '',
              bankAccount: '',
            }
          }
          let contentsCounts = this.response.partners.contentsCounts
          this.config.contentsCard = [
            { title: '전체', value: this.numReplace(contentsCounts.totalCount) },
            { title: '뉴스', value: this.numReplace(contentsCounts.newsCount) },
            { title: '동영상', value: this.numReplace(contentsCounts.videoCount) },
            { title: '웹툰', value: this.numReplace(contentsCounts.toonCount) },
            { title: '포스트', value: this.numReplace(contentsCounts.postCount) },
            { title: '커뮤니티', value: this.numReplace(contentsCounts.ssulCount) },
          ]
          this.config.linkPartners = null
          if (this.response.partners.linkPartner.length > 0) {
            this.config.linkPartners = this.response.partners.linkPartner.map(function (val) {
              return { id: val.media_id, value: val.partner_approved }
            })
          }
          this.mediaList()
        }
      }).catch((error) => {
        if (error.message === 'Request failed with status code 401') {
          this.$router.push({ 'name': 'UserLogin' })
        } else {
          this.snack('warning', '관리자에게 문의 바랍니다.')
        }
      })
    },
  },
  computed: {
    managerPhoneMask () {
      let numberLength = this.vModel.data.company.managerPhone
      if (numberLength === 9) {
        return '####-####'
      } else if (numberLength === 11) {
        return '##-###-####'
      } else if (numberLength === 12) {
        return '###-###-####'
      } else {
        return '###-####-####'
      }
    },
    businessNoMask () {
      return '###-##-######'
    },
    partnersName () {
      if (this.response.partners) {
        return this.response.partners.name
      } else {
        return ''
      }
    },
    resultContents () {
      if (this.response.contents) {
        return this.response.contents
      } else {
        return false
      }
    },
  },
  watch: {
    keyword: function (val) {
      if (val === '') {
        this.submitKeyword()
      }
    },
    contentSelectedData: function (val) {
      if (val) {
        this.contentSelectedFn(val)
      }
    }
  }
}
</script>
